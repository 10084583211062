[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-canada:before {
  content: "\e921";
}
.icon-x:before {
  content: "\e91f";
}
.icon-hamburger:before {
  content: "\e920";
}
.icon-copy:before {
  content: "\e91e";
}
.icon-caret-up:before {
  content: "\e900";
}
.icon-pencil:before {
  content: "\e901";
}
.icon-caret-left:before {
  content: "\e902";
}
.icon-caret-right:before {
  content: "\e903";
}
.icon-caret-down:before {
  content: "\e904";
}
.icon-snowflake:before {
  content: "\e905";
}
.icon-lock:before {
  content: "\e906";
}
.icon-eye:before {
  content: "\e907";
}
.icon-interac:before {
  content: "\e908";
}
.icon-money-bag:before {
  content: "\e909";
}
.icon-bank:before {
  content: "\e90a";
}
.icon-warning:before {
  content: "\e90b";
}
.icon-logout:before {
  content: "\e90c";
}
.icon-graph:before {
  content: "\e90d";
}
.icon-transactions:before {
  content: "\e90e";
}
.icon-transactions--outline:before {
  content: "\e90f";
}
.icon-money:before {
  content: "\e910";
}
.icon-money--outline:before {
  content: "\e911";
}
.icon-transfer:before {
  content: "\e912";
}
.icon-cards:before {
  content: "\e913";
}
.icon-cards--outline:before {
  content: "\e914";
}
.icon-cash:before {
  content: "\e915";
}
.icon-star:before {
  content: "\e916";
}
.icon-star--outline:before {
  content: "\e917";
}
.icon-gear:before {
  content: "\e918";
}
.icon-gear--outline:before {
  content: "\e919";
}
.icon-message:before {
  content: "\e91a";
}
.icon-message--outline:before {
  content: "\e91b";
}
.icon-home:before {
  content: "\e91c";
}
.icon-home--outline:before {
  content: "\e91d";
}
