/* ==========================================================================
  Cognigy
========================================================================== */

[data-cognigy-webchat-root]
  [data-cognigy-webchat-toggle].webchat-toggle-button {
  min-width: auto;
  box-shadow: none;
}



